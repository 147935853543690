import { atom, selector } from 'recoil'

export const langModelState = atom({
  key: 'langModelState',
  default: [],
})

export const langModelListState = selector({
  key: 'langModelListState',
  get: ({ get }) => {
    const data = get(langModelState);
    return Array.isArray(data) ? data : [];
  },
});
