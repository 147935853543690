import React, { useEffect, useState } from 'react'

const SlideOptionGroup = ({
  contentLength,
  loading,
  handleButtonClick,
  optionValues,
  optionRanges,
  onOptionChange,
  sectionTitle = '요약 옵션',
  isActiveGenBtn = false,
  isShowSection = true,
}) => {
  const [showSection, setShowSection] = useState(isShowSection)
  const [isShowOptionLayer, setIsShowOptionLayer] = useState(
    Array(4).fill(false),
  )

  const defaultOptionRanges = {
    temperature: { min: '0.0', max: '1.0' },
    maxOutputToken: { min: '0', max: '1024' },
    topK: { min: '1', max: '40' },
    topP: { min: '0.0', max: '1.0' },
  }

  const getBackgroundRangeStyle = (rangeValue) => {
    const ratio = parseFloat((rangeValue * 100).toFixed(1))
    return {
      background: `linear-gradient(to right, black 0%, black ${ratio}%, rgb(213, 212, 211) ${ratio}%, rgb(213, 212, 211) 100%)`,
    }
  }

  const handleOptionChange = (optionName, value) => {
    onOptionChange(optionName, value)
  }

  // const handleRangeChange = (event) => {
  //   const value = event.target.value;
  //   setRangeValue(value);
  // };

  const handleOptionRangeChange = (optionName, value) => {
    onOptionChange(optionName, value)
    console.log('range test', optionName, value)
  }

  const toggleSection = () => {
    setShowSection(!showSection)
  }

  const showOptionLayer = (index, flag) => {
    setIsShowOptionLayer((prevOptions) => [
      ...prevOptions.slice(0, index),
      flag,
      ...prevOptions.slice(index + 1),
    ])
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const layerModals = document.querySelectorAll('.ico_info')

      let isClickInsideModal = false
      for (const modal of layerModals) {
        if (modal.contains(event.target)) {
          isClickInsideModal = true
          break
        }
      }

      if (!isClickInsideModal) {
        setIsShowOptionLayer(Array(4).fill(false))
      }

      // 클릭된 요소가 모달 외부이면 특정 동작 수행
      // if (modal && !modal.contains(event.target)) {
      //   setIsShowOptionLayer(Array(4).fill(false))
      //   // handleOutsideClick();
      // }
    }

    // document에 클릭 이벤트 리스너 추가
    document.addEventListener('click', handleClickOutside)

    // 컴포넌트가 언마운트되면 리스너 제거
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className="chain_area">
        <header className="title_area">
          <button
            className="title"
            id="toggleButton"
            type="button"
            onClick={toggleSection} // "optionAccordion('accordion')"
            aria-controls="accordion"
            aria-expanded={showSection}
          >
            {sectionTitle} <i className="ico_arrow"></i>
          </button>
        </header>
        <fieldset
          id="accordionContent"
          className={`form ${showSection ? 'show' : ''}`}
          aria-hidden={!showSection}
        >
          <legend className="hide">{sectionTitle}</legend>
          <dl className="input_group">
            <dt>
              <label htmlFor="inputTemperature">원본 유사도</label>
              <button
                type="button"
                className="btn_info"
                onClick={() => showOptionLayer(0, true)}
                aria-label="요약 옵션 정보"
              >
                <i className="ico_info"></i>
              </button>
              <div
                className={`layer_popup layer_info ${isShowOptionLayer[0] ? 'show' : 'hide'}`}
                id="mostviewed1"
              >
                <div className="layer_body">
                  <strong>원본 유사도</strong>
                  <p>
                    창의성 및 랜덤 요소 수준. 수치가 낮을수록 일관되고 예측
                    가능한 결과 생성. 수치가 높을수록 창의적이고 예측 불가능한
                    결과 생성.
                  </p>
                </div>
                <button
                  type="button"
                  className="btn_close"
                  title="Close"
                  onClick={() => showOptionLayer(0, false)}
                >
                  <i className="ico_close"></i>
                  <span className="visually_hidden">닫기</span>
                </button>
              </div>
            </dt>
            <dd>
              <p>
                <span>
                  보수적
                  <br />{' '}
                  {optionRanges?.temperature.min ||
                    defaultOptionRanges.temperature.min}
                </span>
                <input
                  className="range"
                  type="range"
                  name=""
                  min={optionRanges?.temperature.min}
                  max={optionRanges?.temperature.max}
                  value={optionValues.temperature}
                  onChange={(e) =>
                    handleOptionRangeChange('temperature', e.target.value)
                  }
                  step="0.1"
                  style={getBackgroundRangeStyle(
                    optionValues.temperature / optionRanges?.temperature.max,
                  )}
                />
                <span>
                  창의적
                  <br />{' '}
                  {optionRanges?.temperature.max ||
                    defaultOptionRanges.temperature.max}
                </span>
              </p>
              <input
                type="text"
                className="form_control"
                id="inputTemperature"
                name="inputTemperature"
                value={optionValues.temperature}
                onChange={(e) =>
                  handleOptionChange('temperature', e.target.value)
                }
              />
            </dd>
          </dl>
          <dl className="input_group">
            <dt>
              <label htmlFor="inputMaxOutputToken">최대 토큰 수</label>
              <button
                type="button"
                className="btn_info"
                onClick={() => showOptionLayer(1, true)}
                aria-label="요약 옵션 정보"
              >
                <i className="ico_info"></i>
              </button>
              <div
                className={`layer_popup layer_info ${isShowOptionLayer[1] ? 'show' : 'hide'}`}
                id="mostviewed2"
              >
                <div className="layer_body">
                  <strong>최대 토큰 수</strong>
                  <p>
                    생성할 수 있는 최대 단어 또는 문자의 조합 수. 이 값이 클수록
                    더 긴 내용을 생성.
                  </p>
                </div>
                <button
                  type="button"
                  className="btn_close"
                  title="Close"
                  onClick={() => showOptionLayer(1, false)}
                >
                  <i className="ico_close"></i>
                  <span className="visually_hidden">닫기</span>
                </button>
              </div>
            </dt>
            <dd>
              <p>
                <span>
                  짧게
                  <br />{' '}
                  {optionRanges?.maxOutputToken.min ||
                    defaultOptionRanges.maxOutputToken.min}
                </span>
                <input
                  className="range"
                  type="range"
                  name=""
                  min={optionRanges?.maxOutputToken.min}
                  max={optionRanges?.maxOutputToken.max}
                  value={optionValues.maxOutputToken}
                  step="1"
                  onChange={(e) =>
                    handleOptionRangeChange('maxOutputToken', e.target.value)
                  }
                  style={getBackgroundRangeStyle(
                    optionValues.maxOutputToken /
                      optionRanges?.maxOutputToken.max,
                  )}
                />
                <span>
                  길게
                  <br />{' '}
                  {optionRanges?.maxOutputToken.max ||
                    defaultOptionRanges.maxOutputToken.max}
                </span>
              </p>
              <input
                type="text"
                className="form_control"
                id="inputMaxOutputToken"
                name="inputMaxOutputToken"
                value={optionValues.maxOutputToken}
                onChange={(e) =>
                  handleOptionChange('maxOutputToken', e.target.value)
                }
              />
            </dd>
          </dl>
          <dl className="input_group">
            <dt>
              <label htmlFor="inputTopK">top K</label>
              <button
                type="button"
                className="btn_info"
                onClick={() => showOptionLayer(2, true)}
                aria-label="요약 옵션 정보"
              >
                <i className="ico_info"></i>
              </button>
              <div
                className={`layer_popup layer_info ${isShowOptionLayer[2] ? 'show' : 'hide'}`}
                id="mostviewed3"
              >
                <div className="layer_body">
                  <strong>Top K</strong>
                  <p>
                    다음 토큰을 선택할 때 고려하는 후보 토큰 수를 제한. Top K가
                    50이면 AI는 다음 토큰으로 가장 가능성이 높은 50개의 토큰 중
                    하나를 선택.
                  </p>
                </div>
                <button
                  type="button"
                  className="btn_close"
                  title="Close"
                  onClick={() => showOptionLayer(2, false)}
                >
                  <i className="ico_close"></i>
                  <span className="visually_hidden">닫기</span>
                </button>
              </div>
            </dt>
            <dd>
              <p>
                <span>
                  최소
                  <br />{' '}
                  {optionRanges?.topK.min || defaultOptionRanges.topK.min}
                </span>
                <input
                  className="range"
                  type="range"
                  name=""
                  min={optionRanges?.topK.min}
                  max={optionRanges?.topK.max}
                  value={optionValues.topK}
                  step="1"
                  onChange={(e) =>
                    handleOptionRangeChange('topK', e.target.value)
                  }
                  style={getBackgroundRangeStyle(
                    (optionValues.topK - optionRanges?.topK.min) /
                      (optionRanges?.topK.max - optionRanges?.topK.min),
                  )}
                />
                <span>
                  최대
                  <br />{' '}
                  {optionRanges?.topK.max || defaultOptionRanges.topK.max}
                </span>
              </p>
              <input
                type="text"
                className="form_control"
                id="inputTopK"
                name="inputTopK"
                value={optionValues.topK}
                onChange={(e) => handleOptionChange('topK', e.target.value)}
              />
            </dd>
          </dl>
          <dl className="input_group">
            <dt>
              <label htmlFor="inputTopP">top P</label>
              <button
                type="button"
                className="btn_info"
                onClick={() => showOptionLayer(3, true)}
                aria-label="요약 옵션 정보"
              >
                <i className="ico_info"></i>
              </button>
              <div
                className={`layer_popup layer_info ${isShowOptionLayer[3] ? 'show' : 'hide'}`}
                id="mostviewed4"
              >
                <div className="layer_body">
                  <strong>Top P</strong>
                  <p>
                    누적 확률 기반 토큰 선택. Top P가 0.9라면 다음 토큰으로
                    선택될 수 있는 확률 누적이 90%에 도달할 때까지 후보 토큰을
                    고려. 더 다양하고 자연스러운 텍스트 생성을 지원.
                  </p>{' '}
                </div>
                <button
                  type="button"
                  className="btn_close"
                  title="Close"
                  onClick={() => showOptionLayer(3, false)}
                >
                  <i className="ico_close"></i>
                  <span className="visually_hidden">닫기</span>
                </button>
              </div>
            </dt>
            <dd>
              <p>
                <span>
                  최소
                  <br />{' '}
                  {optionRanges?.topP.min || defaultOptionRanges.topP.min}
                </span>
                <input
                  className="range"
                  type="range"
                  name=""
                  min={optionRanges?.topP.min}
                  max={optionRanges?.topP.max}
                  value={optionValues.topP}
                  step="0.01"
                  onChange={(e) =>
                    handleOptionRangeChange('topP', e.target.value)
                  }
                  style={getBackgroundRangeStyle(
                    optionValues.topP / optionRanges?.topP.max,
                  )}
                />
                <span>
                  최대
                  <br />{' '}
                  {optionRanges?.topP.max || defaultOptionRanges.topP.max}
                </span>
              </p>
              <input
                type="text"
                className="form_control"
                id="inputTopP"
                name="inputTopP"
                value={optionValues.topP}
                onChange={(e) => handleOptionChange('topP', e.target.value)}
              />
            </dd>
          </dl>
        </fieldset>
        <button
          type="button"
          className={`btn_l ${(isActiveGenBtn || contentLength > 0) && !loading ? 'active' : ''}`}
          onClick={handleButtonClick}
        >
          생성하기
        </button>
      </div>
    </>
  )
}

export default SlideOptionGroup
