// 백엔드개발팀 제공 API - Cloud Run
export const OSLO_BACKEND_API_DOMAIN = 'https://jmnet-oslo-api-75zws3ssea-du.a.run.app';

// 프론트엔드개발팀 API - Cloud Run
export const OSLO_FRONTEND_API_DOMAIN = 'https://asia-northeast3-the-article-summarization.cloudfunctions.net'

const VERTEXTAI_MODEL_NUMBER = '0.6'
const ARTICLE_MODEL_NUMBER = '0.5'

export const API_URLS = {
    MODEL_VERTEXAI_PARM2_ENGLISH: '/api/vertexai/palm2-eng',
    MODEL_VERTEXAI_PARM3_MODEL_LIST: '/api/vertexai/palm3-model-list',
    MODEL_VERTEXAI_PARM3_COMMON: '/api/vertexai/palm3',
    MODEL_VERTEXAI_MNP: '/api/vertexai/mnp',
    MODEL_VERTEXAI_PROMPTTEST: '/api/vertexai/prompt-test',
}

export const EXTERNAL_API_URLS = {
    VERTEXAI_MODEL_LIST: `${OSLO_BACKEND_API_DOMAIN}/VertexAI/${VERTEXTAI_MODEL_NUMBER}/MethodList`,
    VERTEXAI_TRANSLATE_KOR_TO_END: `${OSLO_FRONTEND_API_DOMAIN}/createAIImage`,
    VERTEXAI_GET_ARTICLE_CONTENT: `${OSLO_BACKEND_API_DOMAIN}/VertexAI/${ARTICLE_MODEL_NUMBER}/articleContent`,
}
