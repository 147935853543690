import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { API_URLS } from '@/src/constants/apis'
import { langModelState, langModelListState } from '@/store/langModel'
import { isShowMenu } from '@/store/common'
import { useRouter } from 'next/router'

const MENU_COUNT = 3

const SideBar = ({ pageId }) => {
  const router = useRouter()
  const { pathname, query } = router; // currentPath와 query를 추출
  const [currentPath, setCurrnetPath] = useState(router.pathname)
  const [subMenuVisibility, setSubMenuVisibility] = useState(
    Array.from({ length: MENU_COUNT }, () => true),
  )
  const [isMenuShow, setIsMenuShow] = useRecoilState(isShowMenu)
  const [langModel, setLangModel] = useRecoilState(langModelState)
  const [isShowModel1Menu, setIsShowModel1Menu] = useState(true)
  const [isShowModel2Menu, setIsShowModel2Menu] = useState(true)
  const langModelList = useRecoilValue(langModelListState)
  const sideBarShadowRef = useRef(null)

  useEffect(() => {
    loadSummarizationModel()
  }, [])

  const loadSummarizationModel = async () => {
    const response = await fetch(API_URLS.MODEL_VERTEXAI_PARM3_MODEL_LIST, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const data = await response.json()
    setLangModel(data)
  }

  const handleLinkClick = (urlLink) => {
    event.preventDefault()
    setIsMenuShow(!(window.innerWidth <= 1024))

    // console.log('Link clicked!', urlLink)
    router.push(urlLink)
  }

  const toggleMainSidebar = () => {
    setIsMenuShow(!isMenuShow)
    // console.log('toogleSidebar')
  }

  const toggleAccordionMenuSidebar = (index) => {
    setSubMenuVisibility((prevState) => {
      const updatedState = [...prevState]
      updatedState[index] = !updatedState[index]
      return updatedState
    })
  }

  // const toggleAccordionMenuSidebar = (index) => {
  //   setSubMenuVisibility((prev) =>
  //     prev.map((item, i) => (i === index ? !item : item)),
  //   )
  //
  //   console.log('subMenuVisibility', subMenuVisibility)
  //
  //   // if (accordionMenu === 'model1') {
  //   //   setIsShowModel1Menu(!isShowModel1Menu)
  //   // } else if (accordionMenu === 'model2') {
  //   //   setIsShowModel2Menu(!isShowModel2Menu)
  //   // }
  // }

  const closeMenuOnOutsideClick = (event) => {
    if (
      sideBarShadowRef.current &&
      sideBarShadowRef.current.contains(event.target)
    ) {
      setIsMenuShow(!(window.innerWidth <= 1024))
    }
  }

  useEffect(() => {
    // console.log('modelList', langModelList)
  }, [langModelList])

  useEffect(() => {
    setIsMenuShow(!(window.innerWidth <= 1024))

    const handleResize = () => {
      setIsMenuShow(!(window.innerWidth <= 1024))
    }

    // 처음 컴포넌트가 마운트될 때 이벤트 리스너를 추가
    window.addEventListener('resize', handleResize)

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거 (메모리 누수 방지)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // const currentPath = window.location.pathname
    // setCurrnetPath(window.location.pathname)
    setCurrnetPath(router.pathname)
    console.log('currentPath', router.pathname)
  }, [router.pathname]) // [pageId])

  useEffect(() => {
    // 전역 이벤트 리스너를 추가하여 메뉴 영역 외부를 클릭할 때 메뉴를 닫습니다.
    document.addEventListener('click', closeMenuOnOutsideClick)

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      document.removeEventListener('click', closeMenuOnOutsideClick)
    }
  }, [])

  return (
    <aside
      id="sidebar"
      className={`sidebar ${isMenuShow ? 'show' : 'hide'}`}
      style={{}}
    >
      <div className="header">
        <button
          type="button"
          className="btn_navigation"
          onClick={toggleMainSidebar}
        >
          <i className="ico_navigation"></i>
        </button>
        <strong>JoongAng AI</strong>
      </div>
      <nav className="nav">
        {/*<dl id="accordion1" className={isShowModel1Menu ? 'show' : ''}>*/}
        {/*  <dt>*/}
        {/*    <button*/}
        {/*      id="toggleButton1"*/}
        {/*      type="button"*/}
        {/*      onClick={() => toggleAccordionMenuSidebar('model1')}*/}
        {/*      aria-controls="accordion"*/}
        {/*      aria-expanded={isShowModel1Menu}*/}
        {/*    >*/}
        {/*      언어 모델1 (영문) <i className="ico_arrow"></i>*/}
        {/*    </button>*/}
        {/*  </dt>*/}
        {/*  <dd className={`${currentPath === '/langai/model1' ? 'active' : ''}`}>*/}
        {/*    <a*/}
        {/*      href="/langai/model1"*/}
        {/*      onClick={() => handleLinkClick('/langai/model1')}*/}
        {/*    >*/}
        {/*      기사 요약·번역*/}
        {/*    </a>*/}
        {/*    /!*<Link href="/langai/model1">기사 요약·번역</Link>*!/*/}
        {/*  </dd>*/}
        {/*</dl>*/}

        {langModelList &&
          langModelList.map((item, outerIndex) => (
            <dl
              key={outerIndex}
              id={`accordion${outerIndex}`}
              className={subMenuVisibility[outerIndex] ? 'show' : ''}
            >
              <dt>
                <button
                  id="toggleButton2"
                  type="button"
                  onClick={() => toggleAccordionMenuSidebar(outerIndex)}
                  aria-controls="accordion"
                  aria-expanded={subMenuVisibility[outerIndex]}
                >
                  {item.title} <i className="ico_arrow"></i>
                </button>
              </dt>
              {item?.list &&
                item.list.map((subItem, innerIndex) => {
                  const segments = pathname.split('/'); // 경로를 '/' 기준으로 나눔

                  const isActive = currentPath.includes(item.code) && Number(query.id) === (innerIndex + 1) // subMenuVisibility[innerIndex] // menuVisibility 배열에서 해당 index 값 가져오기
                  // console.log('sssss', segments[segments.length - 1], pathname, query.id, innerIndex);

                  return (
                    <dd
                      key={`${outerIndex}_${innerIndex}`}
                      className={isActive ? 'active' : ''}
                    >
                      <a
                        href={`/langai/model2/${innerIndex + 1}`}
                        onClick={() =>
                          handleLinkClick(
                            `/langai/${item.code}/${innerIndex + 1}`,
                          )
                        }
                      >
                        {subItem.name}
                      </a>
                      {/*<Link href={`/langai/model2/${index + 1}`}>{item.name}</Link>*/}
                    </dd>
                  )
                })}
            </dl>
          ))}

        {/*<dl id="accordion1" className={isShowModel2Menu ? 'show' : ''}>*/}
        {/*  <dt>*/}
        {/*    <button*/}
        {/*      id="toggleButton2"*/}
        {/*      type="button"*/}
        {/*      onClick={() => toggleAccordionMenuSidebar('model2')}*/}
        {/*      aria-controls="accordion"*/}
        {/*      aria-expanded={isShowModel2Menu}*/}
        {/*    >*/}
        {/*      언어 모델2 <i className="ico_arrow"></i>*/}
        {/*    </button>*/}
        {/*  </dt>*/}
        {/*  {langModelList &&*/}
        {/*    langModelList.map((item, index) => (*/}
        {/*      <dd*/}
        {/*        key={index}*/}
        {/*        className={`${currentPath === `/langai/model2/${index + 1}` ? 'active' : ''}`}*/}
        {/*      >*/}
        {/*        <a*/}
        {/*          href={`/langai/model2/${index + 1}`}*/}
        {/*          onClick={() => handleLinkClick(`/langai/model2/${index + 1}`)}*/}
        {/*        >*/}
        {/*          {item.name}*/}
        {/*        </a>*/}
        {/*        /!*<Link href={`/langai/model2/${index + 1}`}>{item.name}</Link>*!/*/}
        {/*      </dd>*/}
        {/*    ))}*/}
        {/*</dl>*/}

        {/*<dl id="accordion1" className={isShowModel1Menu ? 'show' : ''}>*/}
        {/*  <dt>*/}
        {/*    <button*/}
        {/*        id="toggleButton1"*/}
        {/*        type="button"*/}
        {/*        onClick={() => toggleAccordionMenuSidebar('model1')}*/}
        {/*        aria-controls="accordion"*/}
        {/*        aria-expanded={isShowModel1Menu}*/}
        {/*    >*/}
        {/*      이미지 모델 <i className="ico_arrow"></i>*/}
        {/*    </button>*/}
        {/*  </dt>*/}
        {/*  <dd className={`${currentPath === '/visionai/imagen2' ? 'active' : ''}`}>*/}
        {/*    <a*/}
        {/*        href="/visionai/imagen2"*/}
        {/*        onClick={() => handleLinkClick('/visionai/imagen2')}*/}
        {/*    >*/}
        {/*      이미지 생성*/}
        {/*    </a>*/}
        {/*    /!*<Link href="/langai/model1">기사 요약·번역</Link>*!/*/}
        {/*  </dd>*/}
        {/*</dl>*/}

        {/*<dl id="accordion3">*/}
        {/*  <dt>*/}
        {/*    <button*/}
        {/*      id="toggleButton3"*/}
        {/*      type="button"*/}
        {/*      onClick="toggleAccordion('accordion3')"*/}
        {/*      aria-controls="accordion"*/}
        {/*      aria-expanded="false"*/}
        {/*    >*/}
        {/*      기타 내용 <i className="ico_arrow"></i>*/}
        {/*    </button>*/}
        {/*  </dt>*/}
        {/*  <dd>*/}
        {/*    <a href="#">키워드로 기사 생성</a>*/}
        {/*  </dd>*/}
        {/*  <dd>*/}
        {/*    <a href="#">기사 관련 정보</a>*/}
        {/*  </dd>*/}
        {/*  <dd>*/}
        {/*    <a href="#">영문 기사 요약·번역</a>*/}
        {/*  </dd>*/}
        {/*  <dd>*/}
        {/*    <a href="#">영문 기사 요약·번역</a>*/}
        {/*  </dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt className="fav">*/}
        {/*    <a href="#">즐겨찾기</a>*/}
        {/*  </dt>*/}
        {/*  <dt className="ai">*/}
        {/*    <a href="#">AI 히스토리</a>*/}
        {/*  </dt>*/}
        {/*</dl>*/}
      </nav>
      <span className="shadow" aria-hidden="true" ref={sideBarShadowRef}></span>
    </aside>
  )
}

export default SideBar
